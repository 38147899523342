export default {
  "calendar": {
    "days": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])}
    },
    "months": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
    },
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])}
  },
  "daymenu": {
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées"])},
    "mains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plats"])},
    "sides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garnitures"])},
    "dairy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laitages"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
    "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain"])},
    "breadunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain à l'unité"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIDI"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOIR"])},
    "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu ajouté avec succès"])},
    "allmenus_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIDI (Tous)"])},
    "allmenus_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOIR (Tous)"])},
    "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des allergènes"])},
    "allergies_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LISTE_ALLERGENES"])},
    "backtocalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au calendrier"])},
    "calendar_byday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus du jour"])},
    "calendar_bymonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus totaux"])},
    "error_checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi le nombre maximal d'options pour cette partie. Décochez d'abord une option avant de pouvoir en choisir une autre. "])},
    "error_menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'éléments commandés est supérieur au chiffre rentré. Veuillez corriger d'abord les éléments du menu"])},
    "error_qty_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, il y a des différences entre le nombre de menus souhaités et les quantités commandées (cela est non bloquant)"])},
    "error_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme demandée ne peut excéder le nombre de menus."])},
    "deliveryforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bons de livraison"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "fileadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier ajouté"])},
    "filedeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier supprimé"])},
    "fileupdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier mis à jour"])},
    "limit_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite d'administration : "])},
    "limit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de souscription: "])},
    "managingforentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes pour: "])},
    "meatorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine des viandes"])},
    "meatorigin_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORIGINE_VIANDE"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de menus"])},
    "nomenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de menu disponible pour cette date"])},
    "notfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non publié"])},
    "notvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce menu du jour est en attente de validation"])},
    "orderaddok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande ajoutée"])},
    "orderedmenus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menus commandés"])},
    "orderok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande enregistrée"])},
    "saveok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu enregistré"])},
    "selectmenustotal_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez rentrer un nombre de menus du midi"])},
    "selectmenustotal_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez rentrer un nombre de menus du soir"])},
    "showorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les commandes"])},
    "subscribe_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscriptions par entité"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce menu du jour a été validé"])},
    "weekmenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine"])},
    "weekmenu_lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine (Midi)"])},
    "weekmenu_dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine (Soir)"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])}
  },
  "home": {
    "accesscalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au calendrier"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le site de commande "])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Fourneaux de Marthe & Matthieu"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])}
  },
  "login": {
    "cantlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier vos identifiants."])},
    "checkfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier les champs."])},
    "fieldnotblank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être renseigné"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour commencer, veuillez vous identifier :"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à votre espace"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte utilisateur"])}
  },
  "main": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
    "clickhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez-ici"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
    "erroroccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite:"])},
    "errorcontactadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter votre administrateur si le problème persiste."])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
    "generating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération en cours..."])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["global"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
    "lastupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "showdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le détail"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récap"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
    "uat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CECI EST UN SITE DE TESTS - Pour une utilisation en production"])},
    "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
  },
  "menu": {
    "collective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration Collective"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons fait maison"])}
  }
}