<template>
    <div class="page-login d-flex w-100 h-100 p-3 mx-auto flex-column">
        <h1>{{ t('login.title') }}</h1>

        <form @submit.prevent="submitForm">
            <div class="row mt-4">
                <div class="col d-none d-md-block"></div>
                <div class="col">
                    <div class="row alert alert-danger p-1" v-if="errors.length">
                        <div class="col"><p v-for="error in errors" v-bind:key="error"> {{ error }} </p></div>
                    </div>
                    <div class="row">
                        <div class="col"><input type="email" name="username" class="form-control" v-model="username" :placeholder="t('login.username')"></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col"><input type="password" name="password" class="form-control" v-model="password" :placeholder="t('login.password')"></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col"><button class="form-control btn btn-primary btn-primary-cc">Log in</button></div>
                    </div>
                </div>
                <div class="col d-none d-md-block">
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { useI18n } from 'vue-i18n';

export default {
    name: 'LogIn',
    setup() {
        const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
        })
        return { t }
    },
    data() {
        return {
            username: '',
            password: '',
            errors: []
        }
    },
    methods: {
        async submitForm(e) {

            axios.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem("token")

            const formData = {
                username: this.username,
                password: this.password
            }
            this.errors = [];

            await axios
                .post('/api/v1/token/login/', formData)
                .then(response => {
                    console.log(response)
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    localStorage.setItem('token', token)
                })
                .catch(error => {
                    if(error.response){
                        for(const property in error.response.data)
                        {
                            let p = (property != 'non_field_errors' ? this.t('login.' +  property) + ': ' : '');
                            let msg = this.t('login.checkfield')
                            if(error.response.data[property] == 'This field may not be blank.' ) msg = this.t('login.fieldnotblank')
                            else if(error.response.data[property] == 'Unable to log in with provided credentials.' ) msg = this.t('login.cantlogin')
                            this.errors.push(`${p} ${msg}`)
                        }
                        //console.log(JSON.stringify(error.response.data));
                    }
                    else if(error.message)
                    {
                        console.log(JSON.stringify(error.message));
                    }
                    else
                    {
                        console.log(JSON.stringify(error));
                    }
                })

                axios
                    .get('/api/v1/users/me')
                    .then(response => {
                        this.$store.commit('setUser', {'username': response.data.username, 'id': response.data.id, 'first_name': response.data.first_name})
                        localStorage.setItem('username', response.data.username)
                        //localStorage.setItem('userid', response.data.id)
                        localStorage.setItem('first_name', response.data.first_name)
                        localStorage.setItem('role', response.data.role)
                        localStorage.setItem('can_order_collective', response.data.can_order_collective)
                        localStorage.setItem('can_order_horizons', response.data.can_order_horizons)
                        this.$router.push('/')
                    })
                    .catch(error => {
                        //console.log(JSON.stringify(error));
                    })

        }
    },
}
</script>