import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      id: '',
      username: '',
      first_name:'',
      last_name:'',
      entity:'',
      entity_name:'',
      entity_open_date_limits: false,
      role: '',
      can_order_horizons: false,
      can_order_collective: false,
    },
    isAuthenticated: false,
    token: ''
  },
  getters: {
  },
  mutations: {
    initializeStore(state)
    {
      if(localStorage.getItem('token'))
      { 
        state.token = localStorage.getItem('token'),
        state.isAuthenticated = true
        state.user.id = localStorage.getItem('userid'),
        state.user.username = localStorage.getItem('username'),
        state.user.first_name = localStorage.getItem('first_name')
        state.user.last_name = localStorage.getItem('last_name')
        state.user.entity = localStorage.getItem('entity')
        state.user.entity_name = localStorage.getItem('entity_name')
        state.user.entity_open_date_limits = localStorage.getItem('entity_open_date_limits')
        state.user.role = localStorage.getItem('role')
        state.user.can_order_horizons = localStorage.getItem('can_order_horizons')
        state.user.can_order_collective = localStorage.getItem('can_order_collective')
      } 
      else
      {
        state.user.id = '',
        state.user.username = '',
        state.user.first_name = '',
        state.user.last_name = '',
        state.user.entity = '',
        state.user.entity_name = '',
        state.user.entity_open_date_limits = false,
        state.user.role = '',
        state.user.can_order_horizons = false
        state.user.can_order_collective = false
        state.token = '',
        state.isAuthenticated = false
      }
    },
    setToken(state, token)
    {
      state.token = token,
      state.isAuthenticated = true
    },
    removeToken(state, token)
    {
      state.user.id = '',
      state.user.username = '',
      state.user.first_name = '',
      state.user.last_name = '',
      state.user.entity = '',
      state.user.entity_name = '',
      state.user.entity_open_date_limits = false,
      state.user.role = '',
      state.user.can_order_horizons = false
      state.user.can_order_collective = false
      state.token = '',
      state.isAuthenticated = false
    },
    setUser(state, user){
      state.user = user
    },
  },
  actions: {
  },
  modules: {
  }
})