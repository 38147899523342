<template>
  <div class="CalendarMonthDayByEntity h-100 p-2 d-flex flex-column calendar-month-day-hover" :class="dayClass"  @click="openDay()"> 
    <div class="text-start calendar-day-number">{{ dayNumber }}</div>
    <div class="flex-grow-1 d-flex ">
      <div class="flex-grow-1 align-self-center">
        <div class="row">
          <div class="col-12"><h4> {{ dayTotalMenus }} </h4></div>
          <div class="col small" v-if="dayTotalMenus>0">{{ dayTotalMenusM }}</div>
          <div class="col small" v-if="dayTotalMenus>0">{{ dayTotalMenusS }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numericLiteral } from "@babel/types";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import shared from './shared'

export default defineComponent({
  name: "CalendarMonthDayByEntity",
  created(){
    this.formatDateRaw = shared.formatDateRaw
  },
  props: {
    day: Object,
    refDay: Date,
    dayTotals: Object,
    menutype: String,
  },
  data() {
    return {
      currDay: '',
    }
  },
  computed: {
    dayClass() {
      let retour = 'calendar-month-' + this.dayType;
      if(!this.inMonth && this.day instanceof Date) retour += ' calendar-month-day-past';
      else
      {
        if(this.dayType == 'week' ) retour += '';
        else if(this.dayStatus == 'ok') retour += ' calendar-month-day-ok';
        else if(this.dayStatus == 'inprogress') retour += ' calendar-month-day-inprogress';
        else if(this.dayStatus == 'pending') retour += ' calendar-month-day-pending';
        else if(this.dayStatus == 'warning') retour += ' calendar-month-day-warning';
      }

      if(this.dayStatus != 'nok') retour += ' cursor-hand'

      return retour;
    },
    dayIcon(){
      if(this.dayStatus == 'week' ) return 'calendar-week';
      else if(!this.dayTotals) return 'spinner';
      else if(this.dayStatus == 'ok') return 'check';
      else if(this.dayStatus == 'inprogress') return 'hourglass';
      else if(this.dayStatus == 'pending') return 'pencil';
      else if(this.dayStatus == 'warning') return 'exclamation-triangle';
      else return 'times';
    },
    dayIconClass(){
      return ''//'calendar-month-day-' + this.dayStatus;
    },
    dayNumber(){
      let retour = '';
      if(this.day instanceof Date)
      {
        return this.day.getDate() + (this.inMonth ? '':'/' + (this.day.getMonth() + 1) )
      }
      return  this.t('calendar.week') + ' ' + this.day
    },
    dayStatus(){
      let today = new Date();
      if(this.dayType == 'week') return 'week';
      if(this.$store.state.user.role=="admin")
      {
        if(this.dayTotals && this.dayTotals.finished) return 'ok';
        else if(this.dayTotals && !this.dayTotals.finished) return 'warning';
        else if(this.dayTotals) return 'inprogress';
        else return 'pending';
      }
      else
      {
        return 'nok';
      }
    },
    dayTotalMenusM(){
      let t = 0;
      if(this.dayTotals && this.dayTotals.menustotal_m ) t+= this.dayTotals.menustotal_m;
      return t;
    },
    dayTotalMenusS(){
      let t = 0;
      if(this.dayTotals && this.dayTotals.menustotal_s ) t+= this.dayTotals.menustotal_s;
      return t;
    },
    dayTotalMenus(){
      let t= this.dayTotalMenusM + this.dayTotalMenusS;
      return (t > 0 ? t : '-')
    },
    dayType(){
      if(Number.isInteger(this.day)) return 'week';
      if(this.day.getMonth() == this.refDay.getMonth()) return 'current';
      return 'other';
    },
    inMonth(){
      return this.day instanceof Date ? this.day.getMonth()==this.refDay.getMonth() : false ;
    },
    isPast(){
      return false;
    },
  },
  methods: {
    openDay()
    {
      if(this.dayStatus!='nok') this.$router.push('/calendar/day/' + this.$props.menutype + '/'+this.formatDateRaw(this.day)+'/');
    }
  },
  mounted() {
  },
  components: {
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return { t };
  },
});
</script>
