<template>
  <div class="home">
    <template v-if="$store.state.isAuthenticated">
      <Dashboard />
    </template>
    <template v-else>  
      <Welcome />
      <LoginForm />
    </template>

    
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'
import Welcome from '@/components/Welcome.vue'
import Dashboard from '@/components/Dashboard.vue'
import { useI18n } from 'vue-i18n';

export default {
  name: 'HomeView',
  components: {
    Dashboard,
    LoginForm,
    Welcome,
  },
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },
}
</script>
