<template>
  <div class="CalendarWeek pt-5 pb-3">
    <div class="row">
      <div class="col">
        <h2>{{ title }}</h2>
        {{ subtitle }}
      </div>
      <div class="col-auto"><button class="btn btn-secondary" @click="backToCalendar()">{{ t('daymenu.backtocalendar') }}</button></div>
    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
      <div class="col mt-3">
        <div class="card h-100">
            <div class="card-header">
              <b>{{ t('main.documents') }}</b><span class="small"><br>&nbsp;</span>
            </div>
            <div class="card-body">
                <h5 class="card-title">{{ ucFirst(t('main.info')) }}</h5>
                <div class="row">
                  <div class="info-collective" v-if="menutype=='collective'">
                    <div class="col-12 mb-2">
                      <button class="btn btn-sm btn-outline-primary" :class="$store.state.user.role=='admin' && weekDocs['allergies'] ? 'col-10' : 'col-12'" @click="getWeekDoc(weekDocs['allergies'], 'allergies')" :disabled="(weekDocs['allergies'] ? false : true)">{{ t('daymenu.allergies') }}</button>
                      <button class="btn btn-sm btn-danger col-2" @click="fileDelete('allergies')" v-if="$store.state.user.role=='admin' && weekDocs['allergies']"><font-awesome-icon :icon="['fas', 'trash']"  /></button>
                    </div>
                    <div class="col-12 mb-4" v-if="$store.state.user.role=='admin'">
                      <button class="btn btn-sm btn-outline-secondary col-7" @click="filePick('allergies')">{{ t('main.uploadfile')}}</button>
                      <input type="file" class="d-none" @change="fileChangedAllergies" ref="allergiesInput" accept="application/pdf">
                      <button class="btn btn-sm btn-success col-5" @click="fileSave('allergies')" v-if="uploads.allergies!=''">{{ t('main.save')}}</button>
                    </div>
                    <div class="col-12 mb-2">
                      <button class="btn btn-sm btn-outline-primary" :class="$store.state.user.role=='admin' && weekDocs['meatorigin'] ? 'col-10' : 'col-12'" @click="getWeekDoc(weekDocs['meatorigin'], 'meatorigin')" :disabled="(weekDocs['meatorigin'] ? false : true)">{{ t('daymenu.meatorigin') }}</button>
                      <button class="btn btn-sm btn-danger col-2" @click="fileDelete('meatorigin')" v-if="$store.state.user.role=='admin' && weekDocs['meatorigin']"><font-awesome-icon :icon="['fas', 'trash']"  /></button>
                    </div>
                    <div class="col-12 mb-4" v-if="$store.state.user.role=='admin'">
                      <button class="btn btn-sm btn-outline-secondary col-7" @click="filePick('meatorigin')">{{ t('main.uploadfile')}}</button>
                      <input type="file" class="d-none" @change="fileChangedMeatorigin" ref="meatoriginInput" accept="application/pdf">
                      <button class="btn btn-sm btn-success col-5" @click="fileSave('meatorigin')" v-if="uploads.meatorigin!=''">{{ t('main.save')}}</button>
                    </div>
                  </div>
                    <div class="col-12 mb-2">
                      <button class="btn btn-sm btn-outline-primary form-control" @click="getRecap('weekmenu_lunch')">{{ btnTexts.weekmenu_lunch }}</button>
                    </div>
                    <div class="col-12 mb-2">
                      <button class="btn btn-sm btn-outline-primary form-control" @click="getRecap('weekmenu_dinner')">{{ btnTexts.weekmenu_dinner }}</button>
                    </div>
                </div>
              <template v-if="$store.state.user.role=='admin'">
                <h5 class="card-title">{{ ucFirst(t('main.summary')) }}</h5>
                <div class="row">
                  <div class="col-12 mb-2"><button class="btn btn-sm btn-outline-primary form-control" @click="getRecap('all')">{{ btnTexts.all }}</button></div>
                  <div class="col-12 mb-2" v-for="menuPart in menuLayout"><button class="btn btn-sm btn-outline-primary form-control" @click="getRecap(menuPart.title)">{{ btnTexts[menuPart.title] }}</button></div>
                </div>
              </template>
            </div>
          </div>
      </div>
      <div class="col mt-3" v-for="(weekDay, weekDate, weekDayIndex) in weekDays">
        <div class="card h-100">
          <div class="card-header">
            <div class="row">
              <div class="col-8">
                <b>{{ formatDate(weekDate, false, 'dddd') }}</b>
              </div>
              <div class="col-4 small text-end"><button class="btn btn-sm btn-secondary" @click="openDay(weekDate)" v-if="weekDay.menu">{{ t('main.open') }}</button></div>
              <div class="col-8 small">{{ formatDate(weekDate, true) }}</div>
              <div class="col-4 small" v-if="weekDay.menu && !weekDay.menu.finished"><span class="badge bg-warning">{{ t('daymenu.notfinished')}}</span></div>
            </div>
            
            
             
          </div>
          <div class="card-body">
            <template v-if="weekDay.menu">
                <div class="row">
                  <div class="col text-grey small">
                    <div class="row">
                      <div class="col"></div>
                      <div class="col-2 text-nowrap">{{ t('daymenu.lunch') }}</div>
                      <div class="col-2 text-nowrap">{{ t('daymenu.dinner') }}</div>
                      <div class="col-2 text-nowrap">{{ t('main.total') }}</div>
                    </div>
                  </div>
                </div>
              <template v-for="menuPart in menuLayout">

                <div class="row mb-3">
                  <div class="col text-grey small">
                    <div class="row g-0">
                      <div class="col">{{ t('daymenu.' + menuPart.title)}}&nbsp;</div>
                      <div class="col-2 text-nowrap text-end">{{ sumOrders(weekDay.orders, menuPart, 'm') }}</div>
                      <div class="col-2 text-nowrap text-end">{{ sumOrders(weekDay.orders, menuPart, 's') }}</div>
                      <div class="col-2 text-nowrap text-end"><b>{{ sumOrders(weekDay.orders, menuPart, 'm') + sumOrders(weekDay.orders, menuPart, 's') }}</b></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <template v-for="menuElement in menuPart.elements">
                      <template v-if="weekDay.menu[menuElement]">
                        <div class="row border-bottom small g-0">
                          <div class="col">{{ weekDay.menu[menuElement] }}&nbsp;</div>
                          <div class="col-2 text-nowrap text-end">{{ weekDay.orders['orders'][menuElement + '_m']['total'] }}</div>
                          <div class="col-2 text-nowrap text-end">{{ weekDay.orders['orders'][menuElement + '_s']['total'] }}</div>
                          <div class="col-2 text-nowrap text-end"><b>{{ weekDay.orders['orders'][menuElement + '_m']['total'] + weekDay.orders['orders'][menuElement + '_s']['total'] }}</b></div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
                
              
              </template>
            </template>
            <template v-else>
              <p class="text-grey small">{{ t('daymenu.nomenu')}}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src¡
import { useI18n } from 'vue-i18n';
import shared from '@/components/shared';
import axios from 'axios';
import { toast } from 'bulma-toast'
import { weekdays, weekdaysShort } from 'moment';
const fileDownload = require('js-file-download')

export default {
  name: 'CalendarWeek',
  created(){
    this.ucFirst = shared.ucFirst,
    this.formatDateRaw = shared.formatDateRaw,
    this.formatDate = shared.formatDate,
    this.formatDateTime = shared.formatDateTime,
    this.handleGeneralError = shared.handleGeneralError,
    this.logout = shared.logout,
    this.menuChars = shared.menuChars,
    this.sumOrders = shared.sumOrders,
    this.showToast = shared.showToast
  },
  data() {
    return {
      btnTexts: {
        'all': shared.ucFirst(this.t('main.global')),
        'starters': this.t('daymenu.starters'),
        'mains': this.t('daymenu.mains'),
        'sides': this.t('daymenu.sides'),
        'dairy': this.t('daymenu.dairy'),
        'desserts': this.t('daymenu.desserts'),
        'bread': this.t('daymenu.bread'),
        'menus': this.t('daymenu.menus'),
        'weekmenu_dinner': this.t('daymenu.weekmenu_dinner'),
        'weekmenu_lunch': this.t('daymenu.weekmenu_lunch'),
      },
      menutype: '',
      weekYear: 0,
      weekNum: 0,
      weekDays: {},
      weekDates: [],
      weekDocs: {}, 
      uploads: {'allergies': '', 'meatorigin':''}
    }
  },
  computed: {
    calendarURL(){
      let currDateSplit = this.weekDates[0].split('-')
      return '/calendar/' + this.menutype + '/' + currDateSplit[0] + '/' + currDateSplit[1] + '/';
    },
    menuLayout(){
      return this.menuChars(this.menutype);
    },
    subtitle(){
      return this.t('main.from') + ' ' + this.formatDate(this.weekDates[0]) + ' ' + this.t('main.to') + ' ' + this.formatDate(this.weekDates[this.weekDates.length-1])
    },
    title(){
      return this.t('calendar.week') + ' ' + this.weekNum + '/' + this.weekYear
    },
  },
  components: {
  },
  methods: {
    backToCalendar(){
      this.$router.push(this.calendarURL);
    },
    fileChangedAllergies(event){
      const files = event.target.files
      let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.uploads.allergiesUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.uploads.allergies = files[0]
    },
    fileChangedMeatorigin(event){
      const files = event.target.files
      let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.uploads.meatoriginUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.uploads.meatorigin = files[0]
    },
    fileDelete(fileType) {

      const fd = new FormData();
      fd.append('doctype', fileType);
      fd.append('year', this.weekYear);
      fd.append('weeknum',  this.weekNum);

      axios
          .delete(`/api/v1/weekdocs/${this.weekDocs[fileType]}/`)
          .then( response => {
              this.showToast(this.t('daymenu.filedeleted'));
              console.log(response.data);
              this.weekDocs[fileType] = '';
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
    },
    filePick(fileType) {
      if(fileType=='allergies') this.$refs.allergiesInput.click()
      else if(fileType=='meatorigin') this.$refs.meatoriginInput.click()
    },
    fileSave(fileType) {
      //console.log(this.uploads[fileType]);
      let action = this.weekDocs[fileType] ? 'update' : 'create';
      //console.log(action);

      const fd = new FormData();
      fd.append('doc', this.uploads[fileType]);
      fd.append('doctype', fileType);
      fd.append('year', this.weekYear);
      fd.append('weeknum',  this.weekNum);
      
      if(action=='update')
      {
        axios
          .patch(`/api/v1/weekdocs/${this.weekDocs[fileType]}/`, fd,  { headers: { 'Content-Type': 'multipart/form-data' } }
            )
          .then( response => {
              this.showToast(this.t('daymenu.fileupdated'));
              //console.log(response.data);
              if(response.data.id) 
              {
                this.weekDocs[fileType] = response.data.id;
                this.uploads[fileType] = '';
              }
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
      else if(action=='create')
      {
        axios
          .post(`/api/v1/weekdocs/`, fd,  { headers: { 'Content-Type': 'multipart/form-data' } }
            )
          .then( response => {
              this.showToast(this.t('daymenu.fileadded'));
              //console.log(response.data);
              if(response.data.id) 
              {
                this.weekDocs[fileType] = response.data.id;
                this.uploads[fileType] = '';
              }
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
    },
    getRecap(recaptype) {
      let btnOldText = this.btnTexts[recaptype];
      this.btnTexts[recaptype] = this.t('main.generating');
      axios
          .get(`/api/v1/daymenus/${this.menutype}/week/${this.weekYear}/${this.weekNum}/recap/${recaptype}`, { responseType: 'blob'})
          .then( response => {
            let fname = `RECAP_SEMAINE_${this.menutype}_${this.weekYear}_${this.weekNum}_${recaptype=='all' ? this.t('main.global') : this.t('daymenu.'+recaptype)}`;
            if(recaptype.indexOf('weekmenu')>-1) {
              let weekmenu_split = recaptype.split('_')
              fname = `MENU_SEMAINE_${this.menutype}_${this.weekYear}_${this.weekNum}_${this.t('daymenu.'+weekmenu_split[1])}`;
              }
              this.btnTexts[recaptype] = btnOldText;
              fileDownload(response.data, `${fname.toUpperCase()}.pdf`);
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.btnTexts[recaptype] = btnOldText;
            this.handleGeneralError(error);
          })
    },
    getWeekDays() {
      axios
        .get(`/api/v1/daymenus/${this.menutype}/week/${this.weekYear}/${this.weekNum}`)
        .then(response => {
          //console.log(response.data);
          for(let weekDay in response.data)
          {
            this.weekDays[weekDay] = response.data[weekDay]
            this.weekDates = this.weekDates.concat(weekDay)
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    getWeekDoc(docId, docType) {
      axios
        .get(`/api/v1/weekdocs/download/${docId}/`, {responseType: 'arraybuffer'})
        .then(response => {
          //console.log(response.data);
          fileDownload(response.data, `${this.t('daymenu.' + docType + '_filename')}_${this.weekYear}_SEMAINE_${this.weekNum}.pdf`)
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    getWeekDocs() {
      axios
        .get(`/api/v1/weekdocs/${this.menutype}/week/${this.weekYear}/${this.weekNum}/`)
        .then(response => {
          //console.log(response.data);
          this.weekDocs = response.data;
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    openDay(currDate){
      this.$router.push('/calendar/day/' + this.menutype + '/' +currDate + '/')
    }
  },
  async mounted() {
    this.weekYear = this.$route.params.year;
    this.weekNum = this.$route.params.weeknum;
    this.menutype = this.$route.params.menutype;
    await this.getWeekDays();
    await this.getWeekDocs();
  },
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },
}
</script>
