<template>
  <div class="row mt-5 mb-3">
    <div class="col">{{ t("login.intro") }}</div>
  </div>
  <div class="row p-2" v-if="errors.length">
    <div class="col col-md-10 alert alert-danger p-1">
      <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
  </div>
  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-12 col-md-4">
        <input
          type="email"
          name="username"
          class="form-control"
          v-model="username"
          :placeholder="t('login.username')"
        />
      </div>
      <div class="col-12 col-md-4">
        <input
          type="password"
          name="password"
          class="form-control"
          v-model="password"
          :placeholder="t('login.password')"
        />
      </div>
      <div class="col-12 col-md-2">
        <button class="form-control btn btn-primary btn-primary-cc">
          {{ t("login.submit") }}
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5">
    <div class="col">&nbsp;</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginForm",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return { t };
  },
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },
  methods: {
    async submitForm(e) {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      const formData = {
        username: this.username,
        password: this.password,
      };
      this.errors = [];

      await axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          console.log(response);
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              let p =
                property != "non_field_errors" ? this.t("login." + property) + ": " : "";
              let msg = this.t("login.checkfield");
              if (error.response.data[property] == "This field may not be blank.")
                msg = this.t("login.fieldnotblank");
              else if (
                error.response.data[property] ==
                "Unable to log in with provided credentials."
              )
                msg = this.t("login.cantlogin");
              this.errors.push(`${p} ${msg}`);
            }
            //console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });



      axios
        .get("/api/v1/account")
        .then((response) => {
          console.log(response.data[0]);
          let currUser = response.data[0]
          this.$store.commit("setUser", {
            username: currUser.username,
            id: currUser.id,
            first_name: currUser.first_name,
            last_name: currUser.last_name,
            entity: currUser.entity,
            entity_name: currUser.entity_name,
            entity_open_date_limits: currUser.entity_open_date_limits,
            role: currUser.role,
            can_order_horizons: currUser.can_order_horizons,
            can_order_collective: currUser.can_order_collective,
          });
          localStorage.setItem("username", currUser.username);
          localStorage.setItem("userid", currUser.id);
          localStorage.setItem("first_name", currUser.first_name);
          localStorage.setItem("last_name", currUser.last_name);
          localStorage.setItem("entity", currUser.entity);
          localStorage.setItem("entity_name", currUser.entity_name);
          localStorage.setItem("entity_open_date_limits", currUser.entity_open_date_limits);
          localStorage.setItem("role", currUser.role);
          localStorage.setItem("can_order_horizons", currUser.can_order_horizons);
          localStorage.setItem("can_order_collective", currUser.can_order_collective);
          this.$router.push("/");
        })
        .catch((error) => {
          //console.log(JSON.stringify(error));
        });
    },
  },
});
</script>

<i18n>
{
  "en": {
    "hello": "Hello i18n in SFC!"
  }
}
</i18n>
