<template>
  <div class="calendarMonthDay h-100 p-2 d-flex flex-column cursor-hand calendar-month-day-hover calendar-month-week"  @click="openWeek()"> 
    <div class="text-start calendar-day-number">
    <span class="d-none d-md-block">{{ t('calendar.week')}}</span>
    <span class="d-md-none">{{ t('calendar.week').substring(0,1) }}</span>
     {{ week }}</div>
    <div class="flex-grow-1 d-flex ">
      
    </div>
  </div>
</template>

<script>
import { numericLiteral } from "@babel/types";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import shared from './shared'

export default defineComponent({
  name: "CalendarMonthDay",
  created(){
    this.formatDateRaw = shared.formatDateRaw
  },
  props: {
    week: Number,
    refDay: Date,
    menutype: String,
  },
  data() {
    return {
      currWeek: '',
    }
  },
  computed: {
    
  },
  methods: {
    openWeek()
    {
      this.$router.push('/calendar/week/' + this.$props.menutype + '/'+this.refDay.getFullYear()+'/'+this.week+'/');
    }
  },
  mounted() {
  },
  components: {
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return { t };
  },
});
</script>
