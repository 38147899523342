<template>
  <div class="CalendarMain">
    <CalendarMonth :month="$route.params.month" :year="$route.params.year" :menutype="$route.params.menutype"/>
  </div>
</template>

<script>
// @ is an alias to /src¡
import { useI18n } from 'vue-i18n';
import CalendarMonth from '@/components/CalendarMonth.vue';

export default {
  name: 'CalendarMain',
  components: {
    CalendarMonth
  },
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },
}
</script>
