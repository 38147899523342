<template>
  <div class="CalendarDay pt-5 pb-3">
    <div class="row">
      <div class="col">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="row" v-if="currView=='subscribe' && subscriptionEntity!=''">
      <div class="col">
        <h2>{{ titleSubscriptionsEntity }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col small">
        <span v-if="$store.state.user.role=='admin'">{{ t('daymenu.limit_admin') }} {{ formatDate(currMenu.date_limit_admin) }}<br></span>
        {{ t('daymenu.limit_subscription') }} <span class="badge" :class="subscriptionLabelColor">{{ formatDate(currMenu.date_limit_subscription) }}</span>
        <span class="d-none"><br>{{currView}}<br>{{currMenu}} <br> {{currOrder}} <br> {{allOrders}}<br>{{ typeof $store.state.user.role }}<br>{{  $store.state.user.role=='admin' }}</span>
      </div>
    </div>
    <div class="row" v-if="currView=='overview' ">
      <div class="col-12 col-md small"></div>
      <div class="col-4 col-md-2 small text-end"><span class="badge bg-secondary">{{ t('daymenu.lunch')}}</span> </div>
      <div class="col-4 col-md-2 small text-end"><span class="badge bg-secondary">{{ t('daymenu.dinner')}}</span></div>
      <div class="col-4 col-md-2 small text-end"><span class="badge bg-secondary">{{ t('main.total')}}</span></div>
    </div>
    <div class="row" v-if="currView=='subscribe' ">
      <div class="col-12 col-md small"></div>
      <div class="col-1"></div>
      <div class="col-5 col-md-2 small text-center"><span class="badge bg-secondary">{{ t('daymenu.lunch')}}</span> </div>
      <div class="col-1"></div>
      <div class="col-5 col-md-2 small text-center"><span class="badge bg-secondary">{{ t('daymenu.dinner')}}</span></div>
    </div>
    <div class="row pt-2 pb-2 mt-2 bg-grey" v-if="currView=='subscribe' ">
      <div class="col-12 col-md"><h5>{{ t('daymenu.menustotal')}}</h5></div>
      <div class="col-1"></div>
      <div class="col-5 col-md-2 text-center">
        <input type="number" class="form-control text-end" :placeholder="t('daymenu.lunch')" v-model="currOrder['menustotal_m']" @change="checkMenusTotal('m')" :class="inputClass('menustotal', 'm')">
        <div class="small text-red" v-html="inputErrors('menustotal', 'm')"></div>
      </div>
      <div class="col-1"></div>
      <div class="col-5 col-md-2 text-center">
        <input type="number" class="form-control text-end" :placeholder="t('daymenu.dinner')" v-model="currOrder['menustotal_s']" @change="checkMenusTotal('s')" :class="inputClass('menustotal', 's')">
        <div class="small text-red" v-html="inputErrors('menustotal', 's')"></div>
      </div>
    </div>
    <div class="row mt-3" v-for="menuPart in menuLayout">
      <div class="col">
        <div class="row">
          <div class="col daymenu-header-menupart"><h4>{{ t('daymenu.' + menuPart.title)}}</h4></div>
        </div>
        <div class="row  mt-1 row align-items-center" v-for="(elementName, index) in menuPart.elements">
          <template v-if="elementName in currOrder || $store.state.user.role=='admin'">
            <div class="col-1 col-md-auto">{{ index + 1 }}.</div>
            <div class="col-11 col-md">
              <template v-if="currView=='edit' && elementName!='breadunit'"><input type="text" class="form-control" v-model="currMenu[elementName]"></template>
              <template v-else>{{ elementName=='breadunit' ? t('daymenu.breadunit') : currMenu[elementName] }}</template>
            </div>
            <template v-if="currView=='subscribe'">
              <template v-if="hasMenusLunch">
              <div class="col-1 text-end">
                <input type="checkbox" v-model="currOrder[elementName]['cm']" v-if="currOrder[elementName] && 'cm' in currOrder[elementName] && currView=='subscribe'" @change="checkMenuItems(menuPart, elementName, 'm')">
              </div>
              <div class="col-5 col-md-2 text-center">
                <template v-if="currOrder[elementName] && (currOrder[elementName]['cm'] || !('cm' in currOrder[elementName]))">
                  <div class="popover" :class="inputErrorClass(elementName, 'm')"><div class="popover-content text-red" v-html="inputErrors(elementName, 'm')"></div></div>
                  <input type="number" class="form-control text-end" :placeholder="t('daymenu.lunch')" v-model="currOrder[elementName]['m']" @change="checkMenuNumber(menuPart, elementName, 'm')" :ref="elementName + '_m'" :class="inputClass(elementName, 'm')">
                </template>
              </div>
              </template>
              <template v-else>
              <div class="col-6 col-md-3 text-center small text-grey">{{ t('daymenu.selectmenustotal_m') }}</div>
              </template>
              <template v-if="hasMenusDinner">
              <div class="col-1 text-end">
                <input type="checkbox" v-model="currOrder[elementName]['cs']" v-if="currOrder[elementName] && 'cs' in currOrder[elementName] && currView=='subscribe'" @change="checkMenuItems(menuPart, elementName, 's')" >
              </div>
              <div class="col-5 col-md-2 text-center">
                <template v-if="currOrder[elementName] && (currOrder[elementName]['cs'] || !('cs' in currOrder[elementName]))">
                  <div class="popover" :class="inputErrorClass(elementName, 's')"><div class="popover-content text-red" v-html="inputErrors(elementName, 's')"></div></div>
                  <input type="number" class="form-control text-end" :placeholder="t('daymenu.dinner')" v-model="currOrder[elementName]['s']" @change="checkMenuNumber(menuPart, elementName, 's')" :ref="elementName + '_s'"  :class="inputClass(elementName, 's')">
                </template>
              </div>
            </template>
              <template v-else>
              <div class="col-6 col-md-3 text-center small text-grey">{{ t('daymenu.selectmenustotal_s') }}</div>
              </template>
            </template>
            <template v-if="currView=='read' && currOrder[elementName] && $store.state.user.role!='admin'  ">
              <div class="col-6 col-md-2 text-end"><b>{{ currOrder[elementName]['m'] }}</b></div>
              <div class="col-6 col-md-2 text-end"><b>{{ currOrder[elementName]['s'] }}</b></div>
            </template>
            <template v-if="currView=='overview' && Object.keys(allOrders).length > 0 ">
              <div class="col-4 col-md-2 text-end"><b>{{ allOrders['orders'][elementName + '_m']['total'] }}</b></div>
              <div class="col-4 col-md-2 text-end"><b>{{ allOrders['orders'][elementName + '_s']['total'] }}</b></div>
              <div class="col-4 col-md-2 text-end"><b>{{ allOrders['orders'][elementName + '_m']['total'] + allOrders['orders'][elementName + '_s']['total'] }}</b></div>
              <div class="col-12 small" v-if="allOrdersShowDetails">
                <div class="row" :class="(index == Object.keys(allOrders['entities']).length - 1 ? ' border-bottom ' : '') " v-for="(entityName, entityUid, index) in allOrders['entities']">
                  <div class="col text-end">{{entityName}}</div>
                  <div class="col-3 col-md-2 text-end">{{ allOrders['orders'][elementName + '_m']['byEntity'][entityUid] }}</div>
                  <div class="col-3 col-md-2 text-end">{{ allOrders['orders'][elementName + '_s']['byEntity'][entityUid] }}</div>
                  <div class="col-3 col-md-2 text-end">{{ allOrders['orders'][elementName + '_m']['byEntity'][entityUid] + allOrders['orders'][elementName + '_s']['byEntity'][entityUid] }}</div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <template v-if="isReadEntity">
          <div class="col-12 small mt-3 text-grey">
            <div class="row">
              <div class="col text-end text-bold" :class="currView!='subscribe' ? 'bg-grey' : ''">TOTAL</div>
              <div class="col-1" v-if="currView=='subscribe'"></div>
              <div class="col-4 col-md-2 text-bold" :class="currView=='subscribe' ? 'text-center ' + subtotalClass(menuPart, 'm') : 'text-end bg-grey' ">{{ sumOrder(menuPart,'m') }}</div>
              <div class="col-1" v-if="currView=='subscribe'"></div>
              <div class="col-4 col-md-2 text-bold" :class="currView=='subscribe' ?  'text-center ' + subtotalClass(menuPart, 's'): 'text-end bg-grey' ">{{ sumOrder(menuPart,'s') }}</div>
            </div>
          </div>
        </template>
        <template v-if="currView=='overview'">
          <div class="col-12 small mt-3 text-grey">
            <div class="row">
              <div class="col text-end text-bold bg-grey">TOTAL</div>
              <div class="col-3 col-md-2 text-end text-bold bg-grey">{{ sumOrders(allOrders, menuPart,'m') }}</div>
              <div class="col-3 col-md-2 text-end text-bold bg-grey">{{ sumOrders(allOrders, menuPart,'s') }}</div>
              <div class="col-3 col-md-2 text-end text-bold bg-grey">{{ sumOrders(allOrders, menuPart,'m') + sumOrders(allOrders, menuPart,'s') }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="row mt-5" v-if="isReadEntity">
      <div class="col text-center" v-if="currOrder && currOrder.finished"><span class="badge bg-success">{{ t('daymenu.validated')}}</span></div>
      <div class="col text-center" v-if="currOrder && !currOrder.finished"><span class="badge bg-warning text-black">{{ t('daymenu.notvalidated')}}</span></div>
    </div>
    <div class="row mt-5" v-if="currView=='edit'">
      <div class="col-auto"><b>{{ t('daymenu.finished')}}</b></div>
      <div class="col" >
        <label><input type="radio" v-model="currMenu['finished']" value="false"> {{ t('main.no' )}}</label> &nbsp;
        <label><input type="radio" v-model="currMenu['finished']" value="true"> {{ t('main.yes' )}}</label>
      </div>
    </div>
    <template v-if="menutype=='horizons' && currMenu.id">
      <div class="row mt-5">
        <div class="col-12 col-sm-4 col-md-6"><h4>{{ t('daymenu.documents')}}</h4></div>
        <div class="col-6 col-sm-4 col-md-3">
          <div class="row" v-if="currMenu.title1">
            <div class="col-12 mb-2">
              <button class="btn btn-sm col-12" :class="(menuDocs['menu1'] ? 'btn-primary' : 'btn-outline-primary')" @click="getMenuDoc(menuDocs['menu1'], '1')" :disabled="(menuDocs['menu1'] ? false : true)">{{ t('daymenu.menu') }} 1</button>
              <button class="btn btn-sm btn-danger col-2" @click="fileDelete('menu1')" v-if="$store.state.user.role=='admin' && menuDocs['menu1']"><font-awesome-icon :icon="['fas', 'trash']"  /></button>
            </div>
            <div class="col-12 mb-4" v-if="$store.state.user.role=='admin'">
              <button class="btn btn-sm btn-outline-secondary col-7" @click="filePick('menu1')">{{ t('main.uploadfile')}}</button>
              <input type="file" class="d-none" @change="fileChangedMenu1" ref="menu1Input" accept="application/pdf">
              <button class="btn btn-sm btn-success col-5" @click="fileSave('menu1')" v-if="uploads.menu1!=''">{{ t('main.save')}}</button>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <div class="row" v-if="currMenu.title2">
            <div class="col-12 mb-2">
              <button class="btn btn-sm col-12" :class="(menuDocs['menu2'] ? 'btn-primary' : 'btn-outline-primary')" @click="getMenuDoc(menuDocs['menu2'], '2')" :disabled="(menuDocs['menu2'] ? false : true)">{{ t('daymenu.menu') }} 2</button>
              <button class="btn btn-sm btn-danger col-2" @click="fileDelete('menu2')" v-if="$store.state.user.role=='admin' && menuDocs['menu2']"><font-awesome-icon :icon="['fas', 'trash']"  /></button>
            </div>
            <div class="col-12 mb-4" v-if="$store.state.user.role=='admin'">
              <button class="btn btn-sm btn-outline-secondary col-7" @click="filePick('menu2')">{{ t('main.uploadfile')}}</button>
              <input type="file" class="d-none" @change="fileChangedMenu2" ref="menu2Input" accept="application/pdf">
              <button class="btn btn-sm btn-success col-5" @click="fileSave('menu2')" v-if="uploads.menu2!=''">{{ t('main.save')}}</button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="currView=='overview' && hasOrders">
    <div class="row mt-5">
      <div class="col-12 col-sm-4 col-md-6"><h4>{{ t('daymenu.deliveryforms')}}</h4></div>
      <div class="col-6 col-sm-4 col-md-3"><button class="btn btn-primary w-100" @click="getDeliveryForm('all', 'Tous', 'm')">{{ btnText('all', 'm', t('daymenu.allmenus_m')) }}</button></div>
      <div class="col-6 col-sm-4 col-md-3"><button class="btn btn-primary w-100" @click="getDeliveryForm('all', 'Tous', 's')">{{ btnText('all', 's', t('daymenu.allmenus_s')) }}</button></div>
    </div>
    <div class="row mt-2" v-for="(entityName, entityUid, index) in allOrders['entities']">
      <div class="col-12 col-sm-4 col-md-6">{{ entityName }}</div>
      <div class="col-6 col-sm-4 col-md-3 text-center"><template v-if="entityHasOrders(entityUid, 'm')"><button class="btn btn-outline-primary w-100" @click="getDeliveryForm(entityUid, entityName, 'm')">{{ btnText(entityUid, 'm') }}</button></template><template v-else><button class="btn btn-outline-secondary w-100" disabled="disabled">{{ t('daymenu.lunch')}}</button></template></div>
      <div class="col-6 col-sm-4 col-md-3 text-center"><template v-if="entityHasOrders(entityUid, 's')"><button class="btn btn-outline-primary w-100" @click="getDeliveryForm(entityUid, entityName, 's')">{{ btnText(entityUid, 's') }}</button></template><template v-else><button class="btn btn-outline-secondary w-100" disabled="disabled">{{ t('daymenu.dinner')}}</button></template></div>
    </div>
    </template>
    <template v-if="currView=='overview' && $store.state.user.role=='admin'">
    <div class="row mt-5">
      <div class="col-12 col-sm-4 col-md-6"><h4>{{ t('daymenu.subscribe_entity')}}</h4></div>
      <div class="col-6 col-sm-4 col-md-3">
        <select class="form-select" v-model="subscriptionEntity">
          <option value=""></option>
          <option v-for="(entityName, entityUid) in allEntities" :value="entityUid">
            {{ entityName }}
          </option>
        </select>
      </div>
      <div class="col-6 col-sm-4 col-md-3"><button class="btn btn-secondary" @click="entitySubscribe()">{{ t('main.manage') }}</button></div>
    </div>
    </template>
    <div class="row mt-5 mb-2 align-items-top">
      <div class="col text-center" ><button class="btn btn-outline-secondary" @click="backToCalendar"> {{ t('daymenu.backtocalendar') }}</button></div>
      <div class="col text-center" v-if="currView!='read' && 1==2"><button class="btn btn-outline-secondary" @click="setView('read')"> {{ t('main.cancel') }}</button></div>
      <div class="col text-center" v-if="(currView=='read' || currView=='overview') && $store.state.user.role=='admin'"><button class="btn " :class="currView=='overview' ? 'btn-outline-secondary' : 'btn-primary'" @click="setView('edit')"> {{ t('main.edit') }}</button></div>
      <div class="col text-center" v-if="currView=='read' && $store.state.user.role=='admin'"><button class="btn btn-success" @click="setView('overview')"> {{ t('daymenu.showorders') }}</button></div>
      <div class="col text-center" v-if="currView=='edit'"><button class="btn btn-primary" @click="saveDayMenu"> {{ t('main.save') }}</button></div>
      <div class="col text-center" v-if="currView=='subscribe' && (!this.currOrder.finished || this.currOrder.finished=='false')"><button class="btn btn-secondary" @click="saveDayOrder"> {{ t('main.save') }}</button></div>
      <div class="col text-center" v-if="currView=='subscribe'">
        <button class="btn btn-primary" @click="validateDayOrder" :disabled="validationHasErrors"> {{ t('main.validate') }}<template v-if="subscriptionEntity!=''"><br>({{ allEntities[subscriptionEntity] }})</template></button>
        <div class="small text-red" v-if="validationHasErrors">{{ t('daymenu.error_qty_validation')}}</div>
        </div>
      <div class="col text-center" v-if="currView=='overview' && hasOrders"><button class="btn btn-info" @click="toggleAllOrderDetails()"> {{ t('main.showdetail') }}</button></div>
    </div>
    <div class="row mt-4 mb-5" v-if="currView=='subscribe'">
      <div class="col small">
        {{ t('main.lastupdate') }} {{ formatDateTime(currOrder.date_m) }}
        <br>{{ t('main.created') }} {{ formatDateTime(currOrder.date_c) }}
      </div>
    </div>
    <div class="row mt-4 mb-5" v-if="(currView=='read' || currView=='overview') && $store.state.user.role=='admin'">
      <div class="col small">
        {{ t('main.lastupdate') }} {{ formatDateTime(currMenu.date_m) }}
        <br>{{ t('main.created') }} {{ formatDateTime(currMenu.date_c) }}
      </div>
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src¡
import { useI18n } from 'vue-i18n';
import shared from '@/components/shared';
import axios from 'axios';
import { toast } from 'bulma-toast'
import { faHighlighter } from '@fortawesome/free-solid-svg-icons';
const fileDownload = require('js-file-download')
import { popover } from 'bulma-popover';

export default {
  name: 'CalendarDay',
  created(){
    this.ucFirst = shared.ucFirst,
    this.formatDateRaw = shared.formatDateRaw,
    this.formatDate = shared.formatDate,
    this.formatDateTime = shared.formatDateTime,
    this.getAllEntities = shared.getAllEntities,
    this.handleGeneralError = shared.handleGeneralError,
    this.logout = shared.logout,
    this.menuChars = shared.menuChars,
    this.sumOrders = shared.sumOrders,
    this.showToast = shared.showToast
  },
  data() {
    return {
      allEntities: {},
      allOrders: {},
      allOrdersShowDetails: false,
      btnTexts: {},
      currMenu: {},
      currOrder: {},
      currView: 'read',
      dayLimits: {},
      formClasses: {},
      formErrors: {},
      menuDocs: {}, 
      uploads: {'menu1': '', 'menu2':''},
      menutype:'',
      previousValues: {},
      subscriptionEntity: '',
    }
  },
  computed: {
    calendarURL(){
      let currDateSplit = this.$route.params.date.split('-')
      return '/calendar/' + this.menutype + '/ ' + currDateSplit[0] + '/' + currDateSplit[1] + '/';
    },
    isReadEntity(){
      return (this.currView=='read' && this.$store.state.user.role!='admin')  || this.currView=='subscribe'
    },
    hasOrders(){
      return this.allOrders.entities && Object.keys(this.allOrders.entities).length > 0
    },
    hasMenusDinner() {
      return  this.currOrder.menustotal_s > 0;
    },
    hasMenusLunch() {
      return  this.currOrder.menustotal_m > 0;
    },
    menuLayout(){
      return this.menuChars(this.menutype);
    },
    subscriptionLabelColor(){
      if(this.currMenu && this.currMenu.is_subscriptable && this.currMenu.is_subscription_limit_approaching) return 'bg-danger';
      else if(this.currMenu && this.$store.state.user.entity_open_date_limits) return 'bg-warning';
      else if(this.currMenu && this.currMenu.is_subscriptable) return 'bg-success';
      else return 'bg-secondary';
    },
    title() {
      return this.formatDate(this.currMenu.date_v);
    },
    titleSubscriptionsEntity(){
      //console.log(this.allEntities);
      return this.t('daymenu.managingforentity') + ' ' + this.allEntities[this.subscriptionEntity]
    },
    validationHasErrors(){
      return false;
      let hasErrors = false, sumTotal_m = 0, sumTotal_s = 0;
      for(let menuPart in this.menuLayout)
      {
        if(menuPart != 'bread' && menuPart != 'dessert' && menuPart != 'dairy')
        {
          let partTotal_m = this.sumOrder(this.menuLayout[menuPart], 'm');
          let partTotal_s = this.sumOrder(this.menuLayout[menuPart], 's');
          sumTotal_m += partTotal_m ;
          sumTotal_s += partTotal_s;
          if((partTotal_m > 0 && partTotal_m < this.currOrder.menustotal_m) || (partTotal_s > 0 && partTotal_s < this.currOrder.menustotal_s)) hasErrors = true;
        }
      }
      if(sumTotal_m==0 && this.currOrder.menustotal_m>0 || sumTotal_m>0 && this.currOrder.menustotal_m==0 || sumTotal_s == 0 && this.currOrder.menustotal_s > 0 || sumTotal_s>0 && this.currOrder.menustotal_s==0 ) hasErrors = true;
      return hasErrors;
    }
  },
  methods: {
    backToCalendar(){
      this.$router.push(this.calendarURL);
    },
    btnText(entityUid, turn, defaultText){
      if(!defaultText) defaultText = this.t('daymenu.' + (turn=='m' ? 'lunch' : 'dinner'))
      if(!this.btnTexts[entityUid + '_' + turn]) this.btnTexts[entityUid + '_' + turn] = defaultText
      return this.btnTexts[entityUid + '_' + turn];
    },
    checkMenuItems(partObj, elementName, hour) {
      
      //console.log('checkMenuItems', partObj, elementName, hour);

      let checkedElements = 0, newValue  = this.currOrder[elementName]['c' + hour];
      //console.log(elementName,'wished:', newValue, 'c' + hour);
      //console.log('checkedElements', checkedElements);
      if(newValue)
      {
        for(const e in partObj.elements)
        {
          if(this.currOrder[partObj.elements[e]] && this.currOrder[partObj.elements[e]]['c' + hour]) checkedElements += 1;
        }
        if(checkedElements > partObj.max)
        {
          this.currOrder[elementName]['c' + hour] = false;
          this.currOrder[elementName][hour] = 0;
          alert(this.t('daymenu.error_checkboxes'));
        }
        else this.currOrder[elementName]['c' + hour] = true;
      }
      else
      {
          this.currOrder[elementName][hour] = 0;
      }

      return false;
    },
    checkMenuNumber(menuPart, elementName, shift) {
      
      if(!this.previousValues[elementName]) this.previousValues[elementName] = {};
      if(!this.previousValues[elementName][shift]) this.previousValues[elementName][shift] = 0;

      let totalPart = this.sumOrder(menuPart,shift);

      //console.log(this.previousValues[elementName][shift], this.currOrder[elementName][shift], totalPart);

      if((totalPart <= this.currOrder['menustotal_' + shift] && this.currOrder[elementName][shift] >= 0) || menuPart.title=='bread')
      {
        this.previousValues[elementName][shift] = this.currOrder[elementName][shift];
        this.highlightField(elementName, shift, 'success', '');
      }
      else
      {
        this.currOrder[elementName][shift] = this.previousValues[elementName][shift];
        this.highlightField(elementName, shift, 'danger', this.t('daymenu.error_qty'));
      }


    },
    checkMenusTotal(shift){
      if(!this.previousValues['menustotal_' + shift]) this.previousValues['menustotal_' + shift] = 0;

      let maxNum = 0;
      
      for(let menuPart in this.menuLayout)
      {
        if(menuPart !='bread') maxNum = Math.max(maxNum, this.sumOrder(this.menuLayout[menuPart],shift));
      }
      if(this.currOrder['menustotal_' + shift] < maxNum) 
      {
        this.currOrder['menustotal_' + shift] = maxNum;
        this.highlightField('menustotal', shift, 'danger', this.t('daymenu.error_menustotal'));
      }
      else
      {
        this.highlightField('menustotal', shift, 'success', '');
      }
    },
    entityHasOrders(entityUid, turn){
      let sumItems = 0;
      for(let menuPart in this.allOrders.orders)
      {
        if(menuPart.indexOf('_'+turn)>0)
        {
          sumItems += this.allOrders.orders[menuPart].byEntity[entityUid];
        }
      }
      return sumItems > 0;
    },
    entitySubscribe(){
      this.setView('subscribe');
    },
    fileChangedMenu1(event){
      const files = event.target.files
      let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.uploads.menu1Url = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.uploads.menu1 = files[0]
    },
    fileChangedMenu2(event){
      const files = event.target.files
      let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.uploads.menu2Url = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.uploads.menu2 = files[0]
    },
    fileDelete(fileType) {

      const fd = new FormData();
      fd.append('doctype', fileType);
      fd.append('daymenu', this.currMenu.id);

      axios
          .delete(`/api/v1/menudocs/${this.menuDocs[fileType]}/`)
          .then( response => {
              this.showToast(this.t('daymenu.filedeleted'));
              console.log(response.data);
              this.menuDocs[fileType] = '';
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
    },
    filePick(fileType) {
      if(fileType=='menu1') this.$refs.menu1Input.click()
      else if(fileType=='menu2') this.$refs.menu2Input.click()
    },
    fileSave(fileType) {
      console.log(this.uploads[fileType]);
      let action = this.menuDocs[fileType] ? 'update' : 'create';
      console.log(action);

      const fd = new FormData();
      fd.append('doc', this.uploads[fileType]);
      fd.append('doctype', fileType);
      fd.append('daymenu', this.currMenu.id);
      
      if(action=='update')
      {
        axios
          .patch(`/api/v1/menudocs/${this.menuDocs[fileType]}/`, fd,  { headers: { 'Content-Type': 'multipart/form-data' } }
            )
          .then( response => {
              this.showToast(this.t('daymenu.fileupdated'));
              //console.log(response.data);
              if(response.data.id) 
              {
                this.menuDocs[fileType] = response.data.id;
                this.uploads[fileType] = '';
              }
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
      else if(action=='create')
      {
        axios
          .post(`/api/v1/menudocs/`, fd,  { headers: { 'Content-Type': 'multipart/form-data' } }
            )
          .then( response => {
              this.showToast(this.t('daymenu.fileadded'));
              //console.log(response.data);
              if(response.data.id) 
              {
                this.menuDocs[fileType] = response.data.id;
                this.uploads[fileType] = '';
              }
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
    },
    getDayOrder() {
      //console.log('getDayOrder', this.currMenu, this.currOrder);
      const ReqDate = this.$route.params.date;
      axios
        .get(`/api/v1/dayorders/${this.menutype}/${ReqDate}/${this.subscriptionEntity !='' ? this.subscriptionEntity +'/' : ''}`)
        .then(response => {
          //console.log(response.data);
          this.currOrder.id  = response.data.id;
          this.currOrder.finished  = response.data.finished;
          this.currOrder.date_c  = response.data.date_c;
          this.currOrder.date_m  = response.data.date_m;
          this.currOrder.menustotal_m  = response.data.menustotal_m;
          this.currOrder.menustotal_s  = response.data.menustotal_s;
          
          for(let p in this.currOrder)
          {
            //console.log(p, typeof this.currOrder[p]);
            if(typeof this.currOrder[p] == "object")
            for(let sp in this.currOrder[p])
            {
              //console.log( sp);
              this.currOrder[p][sp] = response.data[p + '_' + sp];
              this.previousValues[p][sp] = response.data[p + '_' + sp];
            }
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    getDayOrders() {
      const ReqDate = this.$route.params.date;
      axios
        .get(`/api/v1/dayorders/${this.menutype}/${ReqDate}/all`)
        .then(response => {
          //console.log(response.data);
          this.allOrders = response.data;
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    getDayMenu() {
      const ReqDate = this.$route.params.date;
      
      //this.currMenu.date_v = ReqDate
      axios
          .get(`/api/v1/daymenus/${this.menutype}/${ReqDate}/`)
          .then( response => {
            if(response.data)
            {
              this.currMenu = response.data;
              this.dayLimits['subscription'] = new Date(this.currMenu.date_limit_subscription);
              this.dayLimits['admin'] = new Date(this.currMenu.date_limit_admin);

              for(const partName in this.menuLayout)
              {
                for(const e in this.menuLayout[partName]['elements'] )
                {
                  let elementName = this.menuLayout[partName]['elements'][e];
                  if(this.currMenu[elementName])
                  {
                    if(!this.currOrder[elementName]) this.currOrder[elementName] = {}
                    if(!this.previousValues[elementName]) this.previousValues[elementName] = {}
                    if(!this.currOrder[elementName]['m']) this.currOrder[elementName]['m'] = 0;
                    if(!this.currOrder[elementName]['s']) this.currOrder[elementName]['s'] = 0;
                    if(!this.previousValues[elementName]['m']) this.previousValues[elementName]['m'] = 0;
                    if(!this.previousValues[elementName]['s']) this.previousValues[elementName]['s'] = 0;
                    if(this.menuLayout[partName]['elements'].length != this.menuLayout[partName]['max'])
                    {
                      if(!this.currOrder[elementName]['cm']) this.currOrder[elementName]['cm'] = false;
                      if(!this.currOrder[elementName]['cs']) this.currOrder[elementName]['cs'] = false;
                    }
                  }
                }
              }
              this.getMenuDocs();
              this.setDefaultView();
            }
          })
          .catch(error => {
              console.log(JSON.stringify(error));
              this.handleGeneralError(error);
          })
    },
    getDeliveryForm(entityUid, entityName, turn){
      const ReqDate = this.$route.params.date;
      let entityNameNormalized = entityName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let btnTextOriginalText = this.btnTexts[entityUid + '_' + turn]
      this.btnTexts[entityUid + '_' + turn] = this.t('main.generating');
      axios
          .get(`/api/v1/dayorders/${this.menutype}/${ReqDate}/deliveryform/${entityUid}/${turn}/`, { responseType: 'blob'})
          .then( response => {
            let fname = `BON_LIVRAISON_${this.menutype}_${ReqDate}_${entityNameNormalized}`;
            fileDownload(response.data, `${fname.toUpperCase()}.pdf`)
            this.btnTexts[entityUid + '_' + turn] = btnTextOriginalText;
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
            this.btnTexts[entityUid + '_' + turn] = btnTextOriginalText;
          })
    },
    getMenuDoc(docId, docType) {
      axios
        .get(`/api/v1/menudocs/download/${docId}/`, {responseType: 'arraybuffer'})
        .then(response => {
          //console.log(response.data);
          fileDownload(response.data, `MENU${docType}_${this.currMenu.date_v}.pdf`)
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    getMenuDocs() {
      const ReqDate = this.$route.params.date;
      axios
        .get(`/api/v1/menudocs/${this.menutype}/${ReqDate}/`)
        .then(response => {
          //console.log(response.data);
          this.menuDocs = response.data;
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    highlightField(elementName, shift, type, errorMsg)
    {
        if(!this.formClasses[elementName]) this.formClasses[elementName] = {};
        if(!this.formErrors[elementName]) this.formErrors[elementName] = {};

        if(type)
        {
          this.formClasses[elementName][shift] = 'alert-' + type;
          if(errorMsg) this.formErrors[elementName][shift] = errorMsg;
          setTimeout(()=>{
            this.highlightField(elementName, shift)
          }, 3000);
        }
        else
        {
          this.formClasses[elementName][shift] = '';
          this.formErrors[elementName][shift] = '';
        }
    },
    inputClass(elementName, shift) {
      if(this.formClasses[elementName] && this.formClasses[elementName][shift]) return this.formClasses[elementName][shift];
      return '';
    },
    inputErrorClass(elementName, shift){
      if(this.formErrors[elementName] && this.formErrors[elementName][shift]) return 'is-popover-active';
      return 'd-none';
    },
    inputErrors(elementName, shift) {
      if(this.formErrors[elementName] && this.formErrors[elementName][shift]) return this.formErrors[elementName][shift];
      return '';
    },
    saveDayMenu(){
      const Date = this.$route.params.date;
      if(this.currMenu.id)
      {
        axios
          .patch(`/api/v1/daymenus/${this.currMenu.id}/`, this.currMenu)
          .then( response => {
              this.showToast(this.t('daymenu.saveok'));
              this.$router.push(this.calendarURL)
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
      else
      {
        if(!this.currMenu.type) this.currMenu.type = this.menutype
        axios
          .post(`/api/v1/daymenus/`, this.currMenu)
          .then( response => {
              this.showToast(this.t('daymenu.addok'));
              this.$router.push(this.calendarURL)
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
    },
    saveDayOrder(backToCalendar){
      const Date = this.$route.params.date;

      if(!this.currMenu.id) {
        //console.log('NO MENU DEFINED TO SAVE WITH');
        return false;
      }
      if(!this.$store.state.user.entity) {
        //console.log('NO USER ENTITY FOUND');
        return false;
      }


      //console.log(this.currMenu);
      //console.log(this.currOrder);
      let currOrderToSave = {};
      currOrderToSave['finished'] = this.currOrder.finished;
      currOrderToSave['daymenu'] = this.currMenu.id;
      currOrderToSave['menustotal_m'] = this.currOrder.menustotal_m;
      currOrderToSave['menustotal_s'] = this.currOrder.menustotal_s;
      currOrderToSave['entity'] = (this.subscriptionEntity != '' ? this.subscriptionEntity : this.$store.state.user.entity);

      for(let p in this.currOrder)
      {
        //console.log(p);
        if(p.indexOf('date_') == -1)
        {
          for(let sp in this.currOrder[p])
          {
            //console.log(sp);
            currOrderToSave[p + '_' + sp] = (this.currOrder[p][sp] && this.currOrder[p][sp]!='' ? this.currOrder[p][sp] : 0);
          }
        }
      }
      //console.log(currOrderToSave);
      //console.log(this.currOrder.id);
      

      if(this.currOrder.id)
      {
        axios
          .patch(`/api/v1/dayorders/${this.currOrder.id}/`, currOrderToSave)
          .then( response => {
              this.showToast(this.t('daymenu.orderok') + ` (${Date})`);
              if(backToCalendar) this.$router.push(this.calendarURL);
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
      else
      {
        axios
          .post(`/api/v1/dayorders/`, currOrderToSave)
          .then( response => {
              this.showToast(this.t('daymenu.orderaddok'));
              if(backToCalendar) this.$router.push(this.calendarURL);
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
          })
      }
    },
    setCurrOrderFromEntity(){
      //console.log('setCurrOrderFromEntity');
      //console.log(this.allOrders['ordersbyentity'][this.subscriptionEntity]);
      //this.currOrder = this.allOrders['ordersbyentity'][this.subscriptionEntity];
      //console.log(this.currOrder);
      /*
      for(let menuItem in this.allOrders['ordersbyentity'][this.subscriptionEntity])
      {
        let menuItemSplit = menuItem.split('_');
        if(this.currOrder[menuItemSplit[0]])
        this.currOrder[menuItemSplit[0]][menuItemSplit[1]] = this.allOrders['ordersbyentity'][this.subscriptionEntity][menuItem];
      }
      console.log(this.currOrder);
      */
    },
    setDefaultView(){
      //console.log('setDefaultView', this.currMenu);
      let currDate = new Date();
      let menuDate = new Date(this.currMenu.date_v);
      if(this.currMenu.finished && this.$store.state.user.role!='admin' && (currDate <= this.dayLimits['subscription'] || this.$store.state.user.entity_open_date_limits  && currDate <= menuDate)) this.setView('subscribe');
      else if(this.currMenu.finished && this.$store.state.user.role!='admin') this.setView('read');
      else if(this.$store.state.user.role=='admin' && this.currMenu.finished) this.setView('overview');
      else if(this.$store.state.user.role=='admin' && currDate <= this.dayLimits['admin']) this.setView('edit');
      //console.log(currDate,this.dayLimits['admin'],currDate <= this.dayLimits['admin']);
    },
    setView(viewName){
      this.currView = viewName
      if(viewName=='subscribe' || viewName=='read') this.getDayOrder();
      else if(viewName=='overview') this.getDayOrders();
    },
    subtotalClass(menuPart, shift){
      let totalPart = this.sumOrder(menuPart, shift);
      if(totalPart > 0  && totalPart == this.currOrder['menustotal_' + shift]) return 'alert-success';
      else if(totalPart > 0 /*&& (menuPart.title=='dairy' || menuPart.title=='desserts')*/) return 'alert-warning';
      else if(totalPart > 0 && menuPart.title!='bread') return 'alert-danger';
      return 'bg-grey';
    },
    sumOrder(menuPart, shift){
      let currSum = 0;
      for(let e in menuPart.elements)
      {
        if(this.currOrder && this.currOrder[menuPart.elements[e]] && this.currOrder[menuPart.elements[e]][shift])
        currSum += this.currOrder[menuPart.elements[e]][shift];
      }
      return currSum;
    },
    toggleAllOrderDetails(){
      return this.allOrdersShowDetails = !this.allOrdersShowDetails
    },
    validateDayOrder(){
      this.currOrder.finished = true;
      this.saveDayOrder(true);
    },
  },
  async mounted() {
    this.menutype = this.$route.params.menutype;
    await this.getDayMenu();
    if(this.$store.state.user.role=='admin') await this.getAllEntities();
  },
  components: {
    
},
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },
}
</script>
