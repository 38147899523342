<template>
  <div class="calendarMonthDay h-100 p-2 d-flex flex-column calendar-month-day-hover" :class="dayClass"  @click="openDay()"> 
    <div class="text-start calendar-day-number">{{ dayNumber }}</div>
    <div class="flex-grow-1 d-flex ">
      <div class="flex-grow-1 align-self-center">
        <font-awesome-icon :icon="['fas', dayIcon]" :class="dayIconShouldBlink"  /> <span class="d-none"> {{dayStatus}} {{ isPast }} {{dayMenu}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { numericLiteral } from "@babel/types";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import shared from './shared'

export default defineComponent({
  name: "CalendarMonthDay",
  created(){
    this.formatDateRaw = shared.formatDateRaw;
    this.inMonth = shared.inMonth;
  },
  props: {
    day: Object,
    refDay: Date,
    dayMenu: Object,
    menutype: String,
  },
  data() {
    return {
      currDay: '',
    }
  },
  computed: {
    dayClass() {
      let retour = 'calendar-month-' + this.dayType;
      if(this.isPast && this.day instanceof Date) retour += ' calendar-month-day-past';
      else
      {
        if(this.dayType == 'week' ) retour += '';
        else if(this.dayStatus == 'ok') retour += ' calendar-month-day-ok';
        else if(this.dayStatus == 'inprogress') retour += ' calendar-month-day-inprogress';
        else if(this.dayStatus == 'pending') retour += ' calendar-month-day-pending';
        else if(this.dayStatus == 'warning') retour += ' calendar-month-day-warning';
      }

      if(this.dayStatus != 'nok') retour += ' cursor-hand'

      return retour;
    },
    dayIcon(){
      if(this.dayStatus == 'week' ) return 'calendar-week';
      else if(!this.dayMenu) return 'spinner';
      else if(this.dayStatus == 'ok') return 'check';
      else if(this.dayStatus == 'inprogress') return 'hourglass';
      else if(this.dayStatus == 'pending') return 'pencil';
      else if(this.dayStatus == 'warning') return 'exclamation-triangle';
      else return 'times';
    },
    dayIconClass(){
      return ''//'calendar-month-day-' + this.dayStatus;
    },
    dayIconShouldBlink(){
      if(this.dayStatus == 'pending' && this.dayMenu && this.dayMenu.is_subscription_limit_approaching)
      {
        return 'fa-beat-fade';//'calendar-month-day-' + this.dayStatus;
      }
      return '';//'calendar-month-day-' + this.dayStatus;
    },
    dayNumber(){
      let retour = '';
      if(this.day instanceof Date)
      {
        return this.day.getDate() + (this.inMonth ? '':'/' + (this.day.getMonth() + 1) )
      }
      return  this.t('calendar.week') + ' ' + this.day
    },
    dayStatus(){
      let today = new Date();
      if(this.dayType == 'week') return 'week';
      if(this.$store.state.user.role=="admin")
      {
        
        if(this.dayMenu && this.dayMenu.finished) return 'ok';
        else if(this.dayMenu && !this.dayMenu.finished && !this.dayMenu.is_editable) return 'warning';
        else if(this.dayMenu) return 'inprogress';
        else return 'pending';
      }
      else
      {
        if(this.dayMenu && this.dayMenu.finished) 
        {
          if(this.dayMenu.entity_order=='done' ) return 'ok';
          else if(!this.dayMenu.is_subscriptable) return 'warning';
          else if(this.dayMenu.entity_order=='pending' ) return 'inprogress';
          return 'pending'; 
        }
        else return 'nok';
      }
    },
    dayType(){
      if(Number.isInteger(this.day)) return 'week';
      if(this.day.getMonth() == this.refDay.getMonth()) return 'current';
      return 'other';
    },
    isPast(){
      return (this.dayMenu ? this.dayMenu.is_past : false);
    },
  },
  methods: {
    openDay()
    {
      if(this.dayStatus!='nok') this.$router.push('/calendar/day/' + this.$props.menutype + '/'+this.formatDateRaw(this.day)+'/');
    }
  },
  mounted() {
  },
  components: {
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return { t };
  },
});
</script>
