<template>
  <div class="row">
    <div class="col d-none d-md-block"><img alt="Les Fourneaux de Marthe et Matthieu logo" src="../assets/img/logo_main.png" class="mt-5 mb-3"></div>
    <div class="col d-md-none"><img alt="Les Fourneaux de Marthe et Matthieu logo" src="../assets/img/logo_main.png" class="mt-5 mb-3" width="150"></div>
  </div>
  <div class="row">
    <div class="col d-none d-md-block">
      <h1>{{ t('home.welcome') }} {{ $store.state.user.first_name }} !</h1>
      <h3>{{ t('home.title') }} {{ t('home.subtitle') }}</h3>
    </div>
    <div class="col d-md-none">
      <h3>{{ t('home.welcome') }} {{ t('home.title') }} {{ t('home.subtitle') }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md text-center mt-3 mb-5" v-if="$store.state.user.can_order_collective">
      <img alt="Cité Caritas" src="../assets/img/logo_caritas.jpg" class="mt-5 mb-3">
      <br>
      <button class="btn btn-primary form-control" @click="goToCalendar('collective')">{{ t('home.accesscalendar' ) }} <br>{{ t('menu.collective') }}</button>
    </div>
    <div class="col-12 col-md text-center mt-3 mb-5" v-if="$store.state.user.can_order_horizons">
      <img alt="Horizons fait maison" src="../assets/img/logo_hfm.jpg" class="mt-5 mb-3">
      <br>
      <button class="btn btn-primary form-control" @click="goToCalendar('horizons')">{{ t('home.accesscalendar' ) }} <br>{{ t('menu.horizons') }}</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Dashboard',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  methods: {
    goToCalendar(calendarName){
      this.$router.push('/calendar/' + calendarName + '/'); 
    }
  },
})
</script>
